import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../shared/button"
import Image from "../shared/image"

const Recovery = styled.section`
  margin: 0 auto;
  maxwidth: 100%;
  padding: 0px 1.0875rem 1.45rem;
`
const Wrapper = styled.div`
  width: 500px;
  @media screen and (max-device-width: 600px) {
    width: 110px;
  }
  @media screen and (max-device-width: 1024px) {
    width: 200px;
  }
  @media screen and (min-width: 1025px) and (max-device-width: 1280px) {
    width: 400px;
  }
  @media screen and (min-device-width: 1025px) and (max-width: 1366px) {
    width: 450px;
  }
`
const RecoveryContent = ({ className }) => {
  const data = useStaticQuery(graphql`
    query RecoveryContentImageQuery {
      asset: file(relativePath: { eq: "assets.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4168, maxHeight: 3335) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      handshake: file(relativePath: { eq: "handshake.png" }) {
        childImageSharp {
          fluid(maxWidth: 674, maxHeight: 371) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Recovery className={className}>
      <div className="w-full lg:w-4/5 mt-5 text-center">
        <h3 className="font-medium text-md lg:text-2xl text-gray-800">
          Archerhub provides you a smooth and seamless process with access to
          two key areas that not all digital-only models have to keep your
          shipments moving:
        </h3>
      </div>
      <div className="flex flex-wrap lg:flex-no-wrap justify-center lg:justify-between items-center pt-12 lg:pt-24">
        <div className="w-full lg:w-1/2 flex lg:flex-none justify-center">
          <Wrapper>
            <Image
              width="450px"
              mobileWidth="100px"
              tabletWidth="220px"
              netbookWidth="200px"
              notebookWidth="300px"
              fluid={data.asset.childImageSharp.fluid}
              title="Digital Freight Brokerage"
              alt="Digital Freight Brokerage"
            />
          </Wrapper>
        </div>
        <div className="w-full lg:w-1/2 lg:pl-20">
          <ul className="list-none text-center lg:text-left">
            <li className="py-2">
              <span className="text-lg lg:text-3xl text-blue-400 font-bold">
                1.
              </span>{" "}
              <span className="font-semibold text-md lg:text-2xl text-gray-700">
                We own the right assets
              </span>
            </li>
            <li className="py-2">
              <span className="text-lg lg:text-3xl text-blue-400 font-bold">
                2.
              </span>{" "}
              <span className="font-semibold text-md lg:text-2xl text-gray-700">
                We have transparent technology
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="py-16 lg:py-24 flex flex-wrap lg:flex-no-wrap justify-center items-center">
        <div className="w-full lg:w-3/5 lg:pl-16">
          <h3 className="text-lg text-gray-800 text-center lg:text-left">
            By working with partners who combine extra capacity with
            sophisticated tools, you can prepare for - and even anticipate -
            inevitable bumps in the road. Have the peace of mind as you prepare
            for a successful season with Archerhub Freight Recovery. When you
            haul or ship with Archerhub, we get your freight where it needs to
            be, when it needs to be there, guaranteed.
          </h3>
        </div>
        <div className="w-full lg:w-2/5 flex lg:flex-none justify-center">
          <Wrapper>
            <Image
              width="300px"
              mobileWidth="100px"
              tabletWidth="200px"
              netbookWidth="200px"
              notebookWidth="300px"
              fluid={data.handshake.childImageSharp.fluid}
              title="Archer hub freight recovery"
              alt="Archer hub freight recovery"
            />
          </Wrapper>
        </div>
      </div>
      <div className="flex justify-center pb-5">
        <a href="https://share.hsforms.com/1N9HrnPLhTLirRJl-mG5_gw3ru87">
          <Button
            className="bg-blue-600 hover:bg-blue-800 text-white lg:font-bold py-2 lg:py-4 px-4 lg:px-8 rounded"
            type="button"
            title="Start Using Freight Recovery Today!"
          >
            Start Using Freight Recovery Today
          </Button>
        </a>
      </div>
    </Recovery>
  )
}

export default RecoveryContent
