import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecoveryContent from "../components/freight-recovery/recovery-content"

const desc = "Keep your shipments moving with our Freight Recovery Program. We combine extra capacity with sophisticated tools so you can prepare for bumps in the road."
const FreightRecovery = () => (
  <Layout>
    <SEO title="Archerhub Freight Recovery Program" description={desc} />
    <RecoveryContent className="container mx-auto flex flex-wrap justify-center"></RecoveryContent>
  </Layout>
)

export default FreightRecovery
